import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import NoFooter from "../core/NoFooter";
import { Button } from "antd";
import {
  MessageOutlined,
  StarFilled,
  HeartOutlined,
  HeartFilled,
} from "@ant-design/icons";
import {
  read,
  startRide,
  endRide,
  loadRideFiles,
  noUserRead,
  subRideEndRequest,
  uploadRideImages,
} from "../actions/ride";
import { createCon } from "../actions/msg";
import {
  addToWishlist,
  getOnlyWishlist,
  removeFromWishlist,
} from "../actions/auth";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../images/Logo";
import axios from "axios";
import ChatModal from "../components/ChatModal";
import moment from "moment";
import "moment/locale/de";
import UploadPicturesModal from "../components/UploadPicturesModal";
import Resizer from "react-image-file-resizer";
import AWS from "aws-sdk";
import { nanoid } from "nanoid/non-secure";

const awsConfig = {
  accessKeyId: "AKIAXTAHSKR3DRGPNNEC",
  secretAccessKey: "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",
  region: "eu-central-1",
  apiVersion: "2010-12-01",
};

const S3 = new AWS.S3(awsConfig);

const uploadImagesToAws = async (image, token, type, ride, customer) => {
  const id = nanoid();

  const imageData = new File([image], `photo.jpeg`);

  const params = {
    Bucket: "leerfahrten24",
    Key: `${id}.jpeg`,
    Body: imageData,
    ACL: "public-read",
    ContentType: `image/jpeg`,
  };

  return new Promise((resolve, reject) => {
    S3.upload(params, async (err, data) => {
      if (err) {
        console.log(err);
        reject(err);
      }
      uploadRideImages(token, type, data.Location, ride, customer);
      resolve(data.Location);
    });
  });
};

const RideDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { auth } = useSelector((state) => ({ ...state }));

  const [start, setStart] = useState();
  const [destination, setDestination] = useState();
  const [waypoints, setWaypoints] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [profileName, setProfileName] = useState();
  const [userId, setUserId] = useState();
  const [rideId, setRideId] = useState();
  const [isUser, setIsUser] = useState(null);
  const [rideStatus, setRideStatus] = useState();
  const [participants, setParticipants] = useState([]);
  const [rideFiles, setRideFiles] = useState([]);
  const [subRideStatus, setSubRideStatus] = useState(false);
  const [find, setFind] = useState(false);
  const [ride, setRide] = useState(false);
  const [hasCon, setHasCon] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const [showChatModal, setShowChatModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [chatModalInput, setChatModalInput] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploadedPictures, setUploadedPictures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");

  const conversationExists = async (ride, receiver) => {
    await axios
      .post(
        "/api/conversation/exists",
        { ride, receiver },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((res) => {
        setHasCon(true);
        setConversationId(res.data._id);
      })
      .catch((err) => console.log(err.response?.data));
  };

  useEffect(() => {
    if (isUser === false) {
      conversationExists(rideId, userId);
    }
  }, [isUser]);

  useEffect(() => {
    if (auth) {
      loadRideDetails();
    } else {
      loadRideDetailsNoLogin();
    }
  }, [auth]);

  const loadRideDetails = async () => {
    let res = await read(auth?.token, id);
    setRide(res.data);
    setStart(res.data.start.address);
    setWaypoints(res.data.waypoints);
    setDestination(res.data.destination.address);
    setProfileName(res.data.user.name);
    setUserId(res.data.user._id);
    setRideId(res.data._id);
    setRideStatus(res.data.status);
    setUploadedPictures(res.data.uploadedPictures);
    if (res.data.participants) {
      setParticipants(res.data.participants);
    }
    if (auth?.user._id !== res.data.user._id) {
      setIsUser(false);
      setFind(res.data.find);
    }
    if (auth?.user._id === res.data.user._id) {
      setIsUser(true);
    }
    return { rideId: res.data._id, userId: res.data.user._id };
  };

  const loadRideDetailsNoLogin = async () => {
    let res = await noUserRead(null, id);
    setRide(res.data);
    setStart(res.data.start.address);
    setWaypoints(res.data.waypoints);
    setDestination(res.data.destination.address);
    setProfileName(res.data.user.name);
    setUserId(res.data.user._id);
    setRideId(res.data._id);
    setRideStatus(res.data.status);
    setIsUser(false);
  };

  const handleLoadRideFiles = async () => {
    if (auth) {
      loadRideFiles(auth?.token, id)
        .then((res) => {
          console.log(res.data);
          setRideFiles(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    handleLoadRideFiles();
  }, []);

  useEffect(() => {
    if (auth) {
      getOnlyWishlist(auth?.token).then((res) => {
        setWishlist(res.data.wishlist);
      });
    }
  }, []);

  let conData = {
    ride: rideId,
    receiver: userId,
    body: chatModalInput,
    date: moment().unix(),
  };

  const createConversation = async () => {
    if (auth) {
      createCon(auth?.token, conData).then((res) => {
        navigate(`/user/chat/${res.data._id}`);
      });
    } else {
      navigate(`/login`);
    }
  };

  const startRideTrigger = async () => {
    startRide(auth?.token, id).then((res) => {
      setRideStatus(res.data.status);
      toast.success("Fahrt gestartet");
    });
  };

  const endRideTrigger = async () => {
    endRide(auth?.token, id).then((res) => {
      setRideStatus(res.data.status);
    });
    await navigate(`/rating/${id}`);
  };

  const handleAddToWishlist = async (e) => {
    e.preventDefault();
    const rideId = e.currentTarget.id;
    if (auth) {
      addToWishlist(auth?.token, rideId).then((res) => {
        setWishlist(res.data);
        toast.success("Zum Merkzettel hinzugefügt");
      });
    }
  };

  const handleRemoveFromWishlist = async (e) => {
    e.preventDefault();
    const rideId = e.currentTarget.id;
    if (auth) {
      removeFromWishlist(auth?.token, rideId).then((res) => {
        setWishlist(res.data);
        toast.error("Aus Merkzettel entfernt");
      });
    }
  };

  const chooseFile = (file) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      70,
      0,
      async (uri) => {
        try {
          setSelectedImages([
            ...selectedImages,
            {
              serverImage: uri,
              clientImage: URL.createObjectURL(file),
            },
          ]);
          console.log(uri);
        } catch (error) {
          console.log(error);
        }
      },
      "blob"
    );
  };

  const uploadImages = () => {
    setLoading(true);
    let promises = [];
    if (selectedImages.length > 0) {
      try {
        selectedImages.forEach((image) => {
          promises.push(
            uploadImagesToAws(
              image.serverImage,
              auth?.token,
              type,
              rideId,
              userId
            )
          );
        });
        Promise.all(promises).then((res) => {
          setLoading(false);
          console.log(res);
          if (type === "end") {
            subRideEndRequest(auth?.token, rideId, userId, moment().unix())
              .then(() => {
                toast.success("Bilder hochgeladen");
                setShowUploadModal(false);
              })
              .catch((err) => console.log(err));
          }
          toast.success("Bilder hochgeladen");
          setShowUploadModal(false);
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
        toast.error("Bilder hochgeladen fehlgeschlagen");
        setShowUploadModal(false);
      }
    } else {
      setLoading(false);
      toast.error("Bitte lade mindestens 1 Foto hoch.");
    }
  };

  return (
    <NoFooter>
      <ChatModal
        show={showChatModal}
        setShow={setShowChatModal}
        input={chatModalInput}
        setInput={setChatModalInput}
        submitHandler={createConversation}
      />
      <UploadPicturesModal
        images={selectedImages}
        addImage={chooseFile}
        loading={loading}
        uploadPictures={uploadImages}
        show={showUploadModal}
        setShow={setShowUploadModal}
      />
      {isUser === true && rideStatus === "open" ? (
        <div className="ride-details-mobile-contact-container">
          <Container>
            <div className="ride-details-profile-details-contact">
              <Button
                type="primary"
                shape="round"
                icon={<MessageOutlined />}
                size="large"
                onClick={startRideTrigger}
              >
                Fahrt starten
              </Button>
            </div>
          </Container>
        </div>
      ) : isUser === true && rideStatus === "ongoing" ? (
        <div className="ride-details-mobile-contact-container">
          <Container>
            <div className="ride-details-profile-details-contact">
              <Button
                type="primary"
                shape="round"
                icon={<MessageOutlined />}
                size="large"
                onClick={endRideTrigger}
              >
                Fahrt beenden
              </Button>
            </div>
          </Container>
        </div>
      ) : null}
      {isUser === false && hasCon && (
        <div className="ride-details-mobile-contact-container">
          <Container>
            <div className="ride-details-profile-details-contact">
              <Button
                type="primary"
                shape="round"
                icon={<MessageOutlined />}
                size="large"
                onClick={() => navigate(`/user/chat/${conversationId}`)}
              >
                Zum Chat
              </Button>
            </div>
          </Container>
        </div>
      )}
      {isUser === false && !hasCon && (
        <div className="ride-details-mobile-contact-container">
          <Container>
            <div className="ride-details-profile-details-contact">
              <Button
                type="primary"
                shape="round"
                icon={<MessageOutlined />}
                size="large"
                onClick={() => setShowChatModal(true)}
              >
                Chat beginnen
              </Button>
            </div>
          </Container>
        </div>
      )}
      <div className="leerfahrten-container">
        {subRideStatus ? (
          <div className="subRide-finish-container">
            <Container>
              Ihre Fahrt wurde beendet. Schauen Sie sich die Bilder an.{" "}
              <Link to={`/ride/end/${id}`}>Hier klicken</Link>
            </Container>
          </div>
        ) : null}
        <div className="ride-details-mobile-top">
          <Container>
            <div className="d-flex align-items-center justify-content-between">
              <span className="top-id">
                ID: <span className="top-id-bold">{id}</span>
              </span>
              <div className="like-container d-flex align-items-center">
                {wishlist && wishlist.includes(id) ? (
                  <HeartFilled
                    id={id}
                    data-key={id}
                    onClick={handleRemoveFromWishlist}
                    style={{
                      cursor: "pointer",
                      fontSize: "24px",
                      color: "#ff441f",
                    }}
                  />
                ) : (
                  <HeartOutlined
                    id={id}
                    onClick={handleAddToWishlist}
                    style={{
                      cursor: "pointer",
                      fontSize: "24px",
                      color: "#ff441f",
                    }}
                  />
                )}
              </div>
            </div>
          </Container>
        </div>
        {isUser === true && (
          <div className="border-container details-isuser-container ride-details-container">
            <div className="ride-details-top-container flex-column d-flex">
              <div className="d-flex align-items-center justify-content-between">
                <span className="top-id">
                  ID: <span className="top-id-bold">{id}</span>
                </span>
                <div className="like-container d-flex align-items-center">
                  {wishlist && wishlist.includes(id) ? (
                    <HeartFilled
                      id={id}
                      data-key={id}
                      onClick={handleRemoveFromWishlist}
                      style={{
                        cursor: "pointer",
                        fontSize: "22px",
                        color: "#ff441f",
                      }}
                    />
                  ) : (
                    <HeartOutlined
                      id={id}
                      onClick={handleAddToWishlist}
                      style={{
                        cursor: "pointer",
                        fontSize: "22px",
                        color: "#ff441f",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span className="ride-details-top-container-heading">
                  Detailansicht
                </span>
                <span className="ride-details-top-container-date">
                  {ride?.date
                    ? moment.unix(ride?.date).format("DD.MM.YYYY HH:mm")
                    : "undefined"}
                </span>
              </div>
            </div>
            <div className="divider"></div>
            <div className="ride-details-body d-flex flex-column">
              <div className="d-flex align-items-center justify-content-between ride-details-mobile-heading">
                <span className="ride-details-top-container-heading">
                  Detailansicht
                </span>
                <span className="ride-details-top-container-date">
                  {ride?.date
                    ? moment.unix(ride?.date).format("DD.MM.YYYY HH:mm")
                    : "undefined"}
                </span>
              </div>
              <div className="ride-details-body-route">
                <h2 className="ride-details-body-heading">Route:</h2>
                <div className="route-row d-flex align-items-center">
                  <div className="route-divider d-flex align-items-center">
                    <div className="divider-dot"></div>
                  </div>
                  <div className="route-address">
                    <p>{start}</p>
                  </div>
                </div>
                <div className="divider-line"></div>
                {waypoints?.length > 0 &&
                  waypoints.map((waypoint) => (
                    <>
                      <div className="route-row d-flex align-items-center">
                        <div className="route-divider d-flex align-items-center">
                          <div className="divider-waypoint-dot"></div>
                        </div>
                        <div className="route-address">
                          <p>{waypoint?.address}</p>
                        </div>
                      </div>
                      <div className="divider-line"></div>
                    </>
                  ))}
                <div className="route-row d-flex align-items-center">
                  <div className="route-divider d-flex align-items-center">
                    <div className="divider-dot"></div>
                  </div>
                  <div className="route-address">
                    <p>{destination}</p>
                  </div>
                </div>
              </div>
              <div className="ride-details-body-description">
                <h2 className="ride-details-body-heading">Beschreibung:</h2>
                <span>
                  {ride?.description
                    ? ride.description
                    : "Es wurde keine Beschreibung hinterlegt."}
                </span>
              </div>
              <div className="ride-details-body-waypoints">
                <div className="d-flex justify-content-between mb-2">
                  <h2 className="ride-details-body-heading">Teilnehmer:</h2>
                  <Link to={`/ride/participants/${id}`}>alle Teilnehmer</Link>
                </div>
                <div className="d-flex align-items-center participants-row">
                  {auth &&
                    participants &&
                    participants[0] &&
                    participants.map((user) => (
                      <div className="border-container mb-0">
                        <div className="d-flex p-1">
                          <div className="rating-img-container">
                            <img src="/images/profile.jpg" alt="" />
                          </div>
                          <div className="rating-text-container">
                            <div className="rating-text-top">
                              <p>{user.user.name}</p>
                              <div className="d-flex align-items-center mt-1">
                                <div>
                                  <b
                                    style={{
                                      marginRight: "3px",
                                      color: "#777",
                                      fontSize: 14,
                                    }}
                                  >
                                    4,7
                                  </b>
                                </div>
                                <StarFilled
                                  style={{
                                    color: "rgb(250, 182, 20)",
                                    fontSize: 14,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {participants.length === 0 && <div>Keine Teilnehmer</div>}
                </div>
              </div>

              <div className="ride-details-body-waypoints">
                <div className="d-flex justify-content-between mb-2">
                  <h2 className="ride-details-body-heading">Bilder:</h2>
                  <Link to={`/ride/pictures/${id}`}>alle Bilder</Link>
                </div>
                <div>
                  <div className="pictures-row">
                    <div className="d-flex pictures-row-flex">
                      {rideFiles &&
                        auth &&
                        auth?.user &&
                        userId &&
                        rideFiles.slice(0, 10).map((r) => (
                          <>
                            <img
                              className="participants-row-img"
                              src={r.url}
                              alt=""
                            />
                          </>
                        ))}
                      {rideFiles.length === 0 && (
                        <div>Keine Bilder hochgeladen</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {rideStatus === "open" && (
                <Button
                  shape="round"
                  className="mt-2 freight-btn freight-btn-prm phone-hide"
                  icon={<MessageOutlined />}
                  size="large"
                  onClick={startRideTrigger}
                >
                  Fahrt starten
                </Button>
              )}
              {rideStatus === "ongoing" && (
                <div>Die Fahrt wurde gestartet.</div>
              )}
            </div>
          </div>
        )}
        {isUser === false && (
          <>
            <div className="ride-details-body-map-mobile">
              <img src="/images/map2.png" alt="map" />
            </div>
            <Container className="pt-4 mobile-margin-container">
              <Row>
                <Col xs={12} md={12} lg={4}>
                  <div className="border-container">
                    <div className="profile-tab-top d-flex">
                      <div className="profile-tab-top-image">
                        <img src="/images/profile.jpg" alt="PB" />
                      </div>
                      <div className="profile-tab-top-details d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <h2 className="profile-name">{profileName}</h2>
                          <div>Rating</div>
                        </div>
                        <div>
                          <a href="">Profil anzeigen</a>
                        </div>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="ride-details-profile-details-body d-flex">
                      {isUser === false && hasCon && (
                        <div className="ride-details-profile-details-contact">
                          <Button
                            type="primary"
                            shape="round"
                            icon={<MessageOutlined />}
                            size="medium"
                            onClick={() =>
                              navigate(`/user/chat/${conversationId}`)
                            }
                          >
                            Zum Chat
                          </Button>
                        </div>
                      )}
                      {isUser === false && !hasCon && (
                        <div className="ride-details-profile-details-contact">
                          <Button
                            type="primary"
                            shape="round"
                            icon={<MessageOutlined />}
                            size="medium"
                            onClick={() => setShowChatModal(true)}
                          >
                            Chat beginnen
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>

                  {isUser === false && find && (
                    <div className="border-container">
                      <div className="ride-details-top-container flex-column d-flex">
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="ride-details-body-heading">
                            Bilder:
                          </span>
                          <span
                            onClick={() => {
                              setType("start");
                              setShowUploadModal(true);
                            }}
                            className="ride-details-body-heading custom-link-white"
                          >
                            +
                          </span>
                        </div>
                      </div>
                      <div className="divider"></div>
                      <div className="ride-details-profile-details-body pictures-row-flex d-flex">
                        {uploadedPictures && uploadedPictures.length > 0 ? (
                          uploadedPictures.map((picture, index) => (
                            <img
                              key={index.toString()}
                              className="participants-row-img"
                              src={picture.url}
                              alt="upload"
                            />
                          ))
                        ) : (
                          <p
                            style={{
                              fontSize: 16,
                              color: "#555",
                              lineHeight: 24,
                            }}
                          >
                            Du hast noch keine Bilder hochgeladen.
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </Col>
                <Col xs={12} md={12} lg={8}>
                  <div className="border-container ride-details-container">
                    <div className="ride-details-top-container flex-column d-flex">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="top-id">
                          ID: <span className="top-id-bold">{id}</span>
                        </span>
                        <div className="like-container d-flex align-items-center">
                          {wishlist && wishlist.includes(id) ? (
                            <HeartFilled
                              id={id}
                              data-key={id}
                              onClick={handleRemoveFromWishlist}
                              style={{
                                cursor: "pointer",
                                fontSize: "22px",
                                color: "#ff441f",
                              }}
                            />
                          ) : (
                            <HeartOutlined
                              id={id}
                              onClick={handleAddToWishlist}
                              style={{
                                cursor: "pointer",
                                fontSize: "22px",
                                color: "#ff441f",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="ride-details-top-container-heading">
                          Detailansicht
                        </span>
                        <span className="ride-details-top-container-date">
                          {moment.unix(ride?.date).format("DD.MM.YYYY HH:mm")}
                        </span>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="ride-details-body d-flex flex-column">
                      <div className="d-flex align-items-center justify-content-between ride-details-mobile-heading">
                        <span className="ride-details-top-container-heading">
                          Detailansicht
                        </span>
                        <span className="ride-details-top-container-date">
                          {moment.unix(ride?.date).format("DD.MM.YYYY HH:mm")}
                        </span>
                      </div>
                      {/* <div className='ride-details-body-map'>
                        <img src='/images/map2.png' alt='map'></img>
                            </div>*/}
                      <h2 className="ride-details-body-heading">Route:</h2>
                      <div className="route-row d-flex align-items-center">
                        <div className="route-divider d-flex align-items-center">
                          <div className="divider-dot"></div>
                        </div>
                        <div className="route-address">
                          <p>{start}</p>
                        </div>
                      </div>
                      <div className="divider-line"></div>
                      {waypoints?.length > 0 &&
                        waypoints.map((waypoint) => (
                          <>
                            <div className="route-row d-flex align-items-center">
                              <div className="route-divider d-flex align-items-center">
                                <div className="divider-waypoint-dot"></div>
                              </div>
                              <div className="route-address">
                                <p>{waypoint?.address}</p>
                              </div>
                            </div>
                            <div className="divider-line"></div>
                          </>
                        ))}
                      <div className="route-row d-flex align-items-center">
                        <div className="route-divider d-flex align-items-center">
                          <div className="divider-dot"></div>
                        </div>
                        <div className="route-address">
                          <p>{destination}</p>
                        </div>
                      </div>

                      <div className="ride-details-body-description">
                        <h2 className="ride-details-body-heading">
                          Beschreibung:
                        </h2>
                        <span>
                          {ride?.description
                            ? ride.description
                            : "Es wurde keine Beschreibung hinterlegt."}
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        )}
        <div className="ride-details-footer">
          <Container className="d-flex align-items-center justify-content-between">
            <div className="ride-details-footer-logo navbar-leerfahrten-brand">
              <Logo />
            </div>
            <div className="ride-details-footer-links d-flex">
              <a href="/impress">Impressum</a>
              <a href="/privacy">Datenschutz</a>
            </div>
          </Container>
        </div>
      </div>
    </NoFooter>
  );
};

export default RideDetails;
